import * as React from 'react';

// Credit to https://simpleicons.org/?q=unsplash
function UnsplashIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="currentColor"
			aria-hidden="true"
		>
			<path d="M7.5 6.75V0h9v6.75h-9zm9 3.75H24V24H0V10.5h7.5v6.75h9V10.5z" />
		</svg>
	);
}

export default UnsplashIcon;
